<template>
    <img
        class="w-auto"
        :src="isDark ? '/betterzone-logo.png' : '/betterzone-logo.png'"
        alt="Logo">
</template>

<script setup>
let isDark = null;

if (useFeature('darkMode')) {
    isDark = useDark({
        disableTransition: false
    })
}
</script>